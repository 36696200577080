.App {
  text-align: center;
  background-color: #282c34;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #484c54;
  color: #909090;
}

.App-link {
  color: #61dafb;
}

#logo {
  padding: .2em .5em;
  margin: .2em;
  border: 2px solid white;
  border-radius: .3em;
}